<template>
  <div class="view-login pt-lg-0">
    <div class="mb-3 d-flex justify-content-center justify-content-lg-start">
      <h1 class="d-none d-lg-block">Instituto Eurico Bergsten</h1>
      <h2 class="d-lg-none">Instituto Eurico Bergsten</h2>
    </div>

    <form @submit.prevent="submit">
      <h4 class="text-dark mb-3 ">
        Bem-vindo(a) de volta!
      </h4>

      <form-group
        v-model="form.email"
        id="email"
        label="E-mail"
        type="email"
        placeholder="Digite seu e-mail"
        :errors="errors.email"
      />

      <form-group
        v-model="form.password"
        class="mb-lg-4"
        id="password"
        icon="icon-password"
        label="Senha"
        type="password"
        placeholder="Digite sua senha"
        :errors="errors.password"
      />

      <p class="text-right mb-4 mb-lg-3">
        <router-link :to="{ name: 'forgot-password' }">
          Esqueci minha senha
        </router-link>
      </p>

      <div class="text-center mb-3">
        <button
          class="btn btn-primary btn-fixed-size"
          type="submit"
          :disabled="loading"
        >
          <loading :show="loading">Entrar</loading>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import FIREBASE_ERROR from "@/constants/firebaseError.js";
import { formFields } from "@/functions/helpers.js";

export default {
  name: "login",
  data() {
    return {
      ...formFields(["email", "password"]),
      loading: false,
      forgotDialog: false
    };
  },
  methods: {
    submit() {
      this.$message.hide();

      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("user/login", this.form)
        .then(() => {
          // if (this.$route.query.path) {
          //   this.$router.push(this.$route.query.path);
          // } else {
          this.$router.push({ name: "home", params: { first_access: true } });
          // }
        })
        .catch(error => {
          this.$message.error(FIREBASE_ERROR[error.code]);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  width: 100%;
  h4 {
    @media screen and (max-width: 991px) {
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>
